import { useNavigate } from 'react-router-dom'
import Header from '../components/Header'

const LandingPage = () => {
  const navigate = useNavigate()
  return (
    <div className="landing-background flex flex-col h-full min-h-screen items-center">
      <Header />
      <div className="w-full flex flex-col justify-center max-w-[1200px]">
        <div className="w-full px-16 pt-20 z-10">
          <h1 className="font-semibold text-right italic text-3xl md:text-5xl text-white">
            Your Ultimate Solution for {<br />}
            Captivating Social Media Posts!
          </h1>
        </div>
        <div className="w-full flex justify-end py-6 md:py-10 px-20">
          <button
            onClick={() => navigate('/create')}
            className="w-40 md:w-60 h-12 bg-yellowAccent text-accent rounded-lg text-md md:text-lg font-semibold"
          >
            Try Vyyme!
          </button>
        </div>
        <div className="flex w-full px-20 py-2 md:py-10">
          <p className="text-white font-thin text-sm sm:text-lg md:text-xl w-80 md:w-[550px]">
            Our innovative text-to-image convertor tool transforms your ordinary
            text posts into visually stunning images that demand to be noticed.
          </p>
        </div>
        <div className="w-full h-[400px] md:h-[600px] p-10 pt-32 flex justify-center relative overflow-x-hidden">
          <div className="w-[800px] flex justify-center">
            <img
              src="./assets/example_image.png"
              alt="example imgae"
              className="w-40 sm:w-48 md:w-96 absolute mr-56 md:mr-[28rem] -rotate-6 hover:z-10 hover:shadow-2xl hover:shadow-white transition ease-in-out"
            />
            <img
              src="./assets/example_image_2_1.png"
              alt="example imgae"
              className="w-40 sm:w-48 md:w-96 ml-56 md:ml-[28rem] rotate-6 absolute hover:z-10 hover:shadow-2xl hover:shadow-white transition ease-in-out"
            />
            <img
              src="./assets/example_image_3.png"
              alt="example imgae"
              className="w-40 sm:w-48 md:w-96 absolute top-10  hover:z-10 hover:shadow-2xl hover:shadow-white transition ease-in-out"
            />
          </div>
        </div>
        <div className="flex w-full justify-center">
          <h2 className="text-2xl md:text-4xl font-semibold">
            For creators that aren’t afraid to standout
          </h2>
        </div>

        <div className="flex h-[400px] relative flex-col justify-center items-center w-full my-12 gap-20">
          <img
            src="./assets/orange_shape.svg"
            alt="orange shape"
            className="w-64 absolute top-1 left-10 hidden md:block"
          />
          <img
            src="./assets/purple_shape.svg"
            alt="purple shape"
            className="w-52 absolute bottom-1 right-10 hidden md:block"
          />
          <p className="max-w-[500px] font-thin text-center">
            Create visual content that boosts social media followers and gain
            impressions. Convert your opinnions into eye-catching high
            conversion content. Create visual content that boosts social media
            followers and gain impressions. Convert your opinnions into
            eye-catching high conversion content.
          </p>
          <p className="max-w-[500px] font-thin text-center">
            Imagine turning your thoughts and messages into stunning visuals
            that stand out in crowded feeds. Our platform enables you to create
            visual content that not only enhances your posts but also attracts
            more followers and boosts your overall reach.
          </p>
        </div>
        <div className="w-full flex justify-center p-20">
          <button
            onClick={() => navigate('/create')}
            className="w-40 md:w-60 h-12 bg-yellowAccent text-accent rounded-lg text-md md:text-lg font-semibold"
          >
            Try Vyyme!
          </button>
        </div>
      </div>
    </div>
  )
}

export default LandingPage
